<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Device agnostic and inclusive with support for most popular fitness apps and watches</h1>
      <p>
        We offer a device agnostic platform which support almost all popular fitness trackers currently on the market. 
        Our platform allows you to host inclusive challenges which are motivating all of your workforce, not only the most competitive few!
      </p>
    </div>
    <div>
      <h2 class="subheader mt-8">Integration with all major fitness trackers</h2>
      <p>
        We offer native integration with all major fitness wearables for automatic upload of activities. This greatly improves the experience of your participants! <br/>
        Our manual entry tool allows quick entry for everyone without a connected GPS device.
      </p>
      <SectionIntegrations />
    </div>

    <div>
      <h2 class="subheader mt-8">Native iOS and Android app</h2>
      <p>
        Download our app for iPhone and Android to track your activities and see the challenge progress in real-time!
      </p>
      <p>
        <v-btn text color="primary" to="/get">Read more</v-btn>
      </p>
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/web/app/cofi-app-devices.png?w=1080" alt="Get our app." /> 
    </div>

    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="subtitle">Download our app!</h1>
        </v-col>
        <v-col cols="6" style="display:flex;flex-direction:column;align-items:flex-end;">
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
        </v-col>
        <v-col cols="6">
          <a href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </v-col>
      </v-row>
    </v-container>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Get more healthy employees</h2>
            <h1 class="subtitle">Create engaging and beautiful experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/request-demo">Request Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div> 

  </div>

</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>